.wrapper {
	position: fixed;
	bottom: 15px;
	left: 15px;
	display: flex;
	align-items: stretch;
	color: #fff;
	font-weight: bold;
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%);
	max-width: 500px;
	z-index: 9999;
}

.countdown {
	height: 7px;
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 0;
	left: 0;
}

.close {
	margin-top: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 25px;
	position: relative;
	font-size: 1.2rem;
}

.close:hover {
	cursor: pointer;
	color: #fff;
}

.close:hover:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	display: block;
	opacity: 0.2;
}

.text {
	padding: 20px;
	margin: 0;
	margin-top: 7px;
}

.alert {
	background-color: orangered;
}

.success {
	background-color: seagreen;
}

@media screen and (max-width: 800px) {
	.wrapper {
		max-width: calc(100% - 30px) !important;
	}
}
