.map-toggle {
	position: fixed;
	width: 60px;
    height: 60px;
	background-color: #ffdb56;
	padding: 13px;
	border-radius: 50%;
	box-shadow: 0px 0px 6px 3px rgb(60 64 67 / 36%);
	display: none;
	z-index: 999;
}

.map-toggle .icon {
	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
}

@media screen and (max-width: 800px) {
	.map-toggle {
		display: block;
	}

	.map-container-active {
		transform: translateX(0);
	}
}
