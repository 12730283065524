.header {
	display: flex;
	justify-content: space-between;
	padding: 0 25px;
	height: 63px;
	align-items: center;
	position: fixed;
	width: 55%;
	max-width: 800px;
	background-color: #fff;
	z-index: 100;
	box-shadow: -2px 0px 3px 0px #a3a3a3;
}

.logo {
    font-weight: bold;
    font-size: 1.5rem;
}
.logo:hover {
    cursor: pointer;
}

.nav {
	display: flex;
	align-items: center;
}
.nav a {
	text-decoration: none;
	margin: 0 10px;
	color: #000;
	padding: 5px 0;
}

.nav .active {
	border-bottom: 2px solid #CD113B;
}

.user {
	display: flex;
	gap: 5px;
	position: relative;
	padding: 10px;
	align-items: center;
}

.user:hover {
	cursor: pointer;
}

.user-menu {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 100%;
	background-color: #fff;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	min-width: 100%;
	z-index: 9999;
}

.user-menu a,
.user-menu span {
	margin: 0;
	cursor: pointer;
	padding: 10px 15px;
}

.user-menu a:hover,
.user-menu span:hover {
	background-color: #e8eaed;
}

.toggle-menu {
	flex-direction: column;
	display: none;
    position: relative;
    z-index: 9;
    height: 25px;
    width: 30px;
    overflow: hidden;
}

.toggle-menu span {
    width: 100%;
	height: 5px;
	background-color: black;
    position: absolute;
    right: 0;
    transition: all ease 0.3s;
    border-radius: 20px;
}

.toggle-menu span:nth-child(1) {
	top: 0;
}

.toggle-menu span:nth-child(2) {
	top: 10px;
}

.toggle-menu span:nth-child(3) {
	top: 20px;
}

.toggle-menu-active span:nth-child(1) {
	top: 10px;
    transform: rotate(-45deg);
}

.toggle-menu-active span:nth-child(2) {
    transform: translateX(100%);
    opacity: 0;
}

.toggle-menu-active span:nth-child(3) {
	top: 10px;
    transform: rotate(45deg);
}
@media screen and (max-width: 800px) {
	.header {
		width: 100%;
	}
	.toggle-menu {
		display: flex;
	}

	.nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #fff;
		flex-direction: column;
		gap: 20px;
		transform: translateX(100%);
		transition: all ease 0.3s;
        text-align: center;
        margin-top: 63px;
	}

	.active {
		transform: translateX(0);
	}

    .user-menu {
        box-shadow: none;
        left: 0;
    }
}
