@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body {
	margin: 0;
	font-family: "Roboto", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

img {
	width: 100%;
}

*:focus {
	outline: none;
}

/* SCROLLBAR */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

::-webkit-scrollbar {
	width: 3px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #cd113b;
}

/* GLOBAL STYPE */

.form {
	width: 60%;
	margin: 20px auto;
	padding: 10px;
}

.form-group {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	margin-bottom: 10px;
}

.form-group .form-control {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-flex: 1 1;
	        flex: 1 1;
	margin-right: 10px;
	margin-bottom: 0;
}

.form-group > button {
	min-width: 40px;
	padding: 0 10px;
	line-height: 36px;
	color: #fff;
	font-weight: bold;
	font-size: 1.2em;
	border: none;
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%);
}

.form-group > button:first-of-type {
	background-color: #32a9d7;
}

.form-group > button:nth-of-type(2) {
	background-color: #ee2756;
}

.form-group > button:hover {
	cursor: pointer;
}

.form-group label {
	display: none;
}

.form-control {
	display: -webkit-flex;
	display: flex;
	margin-bottom: 20px;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: relative;
}

.form-control.input-button-group {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.form-control.input-button-group label {
	margin: 0 !important;
	width: 40px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	border-width: 1px 0 1px 1px;
	border-style: solid;
	border-color: rgb(133, 133, 133);
}

.form-control.input-button-group .error {
	width: 100%;
}

.form-control.input-button-group label {
	margin-right: 10px;
}

.form-control label {
	margin-bottom: 5px;
	font-size: 0.8rem;
	font-weight: bold;
    color: #444444;
}

.form-control .input-group {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}

.form-control input,
.form-control select,
.form-control textarea {
	padding: 10px 5px;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	font-family: "Roboto", sans-serif !important;
	border: none;
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%);
}

.form-control button {
	padding: 10px 5px;
	width: 12%;
}

.form-control .error {
	color: lightcoral;
	font-size: 0.7rem;
	font-style: italic;
	margin-top: 5px;
}

.form-action .btn {
	padding: 7px 15px;
	background-color: #32a9d7;
	border: none;
	color: #fff;
	margin-right: 15px;
	box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px -1px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
	font-size: 14px;
	font-weight: 500;
	font-family: Roboto, sans-serif;
	padding: 14px;
	border-radius: 3px;
}

.form-action .btn.btn-danger {
	background-color: #ee2756;
}

.form-action .btn:hover {
	cursor: pointer;
}

@media screen and (max-width: 1350px) {
	.form {
		width: 80%;
	}
}

@media screen and (max-width: 800px) {
	::-webkit-scrollbar {
		display: none;
	}
}

/* SLICK SLIDER */
.slick-prev {
	z-index: 1;
	left: 5px !important;
}

.slick-next {
	z-index: 1;
	right: 5px !important;
}

.slick-prev,
.slick-next {
	height: 35px;
	width: 35px;
}

.slick-dots {
	bottom: 10px !important;
}

.slick-dots li button:before {
	font-size: 15px !important;
	color: #fff !important;
	opacity: 0.45 !important;
}

.slick-dots li.slick-active button:before {
	opacity: 1 !important;
}


.Layout_layout__1nyUT {
	display: -webkit-flex;
	display: flex;
}

.Layout_main-container__3F3sN {
	width: 55%;
	position: relative;
	max-width: 800px;
}

.Layout_main__2jDv9 {
	padding: 20px 20px;
	margin-top: 63px;
	background: #f4f4f4;
	height: calc(100vh - 63px);
	overflow-y: scroll;
}

.Layout_map-container__bSEx6 {
	width: 45%;
	overflow: hidden;
	position: relative;
	-webkit-flex: 1 1;
	        flex: 1 1;
}

.Layout_map-container__bSEx6::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 5px;
	left: -5px;
	top: 0;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	display: block;
	z-index: 999;
}



@media screen and (max-width: 800px) {
	.Layout_main-container__3F3sN {
		width: 100%;
	}

	.Layout_map-container__bSEx6 {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		height: 100%;
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
		transition: 0.3s all ease;
		margin-top: 63px;
		height: calc(100% - 63px);
	}

	.Layout_main__2jDv9 {
		padding: 20px 5px;
	}

	.Layout_map-toggle__3vDah {
		display: block;
	}

	.Layout_map-container-active__Xb4h1 {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.MapMaker_infobox__12yBf {
	background-color: #fff;
    font-weight: bold;
	padding: 5px;
	-webkit-transform: translate(-50%, calc(-100% - 49px));
	        transform: translate(-50%, calc(-100% - 49px));
    padding: 10px;
    font-size: 1.2em;
    box-shadow: rgba(48, 48, 48, 0.2) 0px 2px 8px 0px;
}

.MainHeader_header__b3nih {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 0 25px;
	height: 63px;
	-webkit-align-items: center;
	        align-items: center;
	position: fixed;
	width: 55%;
	max-width: 800px;
	background-color: #fff;
	z-index: 100;
	box-shadow: -2px 0px 3px 0px #a3a3a3;
}

.MainHeader_logo__34EZz {
    font-weight: bold;
    font-size: 1.5rem;
}
.MainHeader_logo__34EZz:hover {
    cursor: pointer;
}

.MainHeader_nav__3rIJH {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.MainHeader_nav__3rIJH a {
	text-decoration: none;
	margin: 0 10px;
	color: #000;
	padding: 5px 0;
}

.MainHeader_nav__3rIJH .MainHeader_active__2mVoz {
	border-bottom: 2px solid #CD113B;
}

.MainHeader_user__3nOSu {
	display: -webkit-flex;
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	position: relative;
	padding: 10px;
	-webkit-align-items: center;
	        align-items: center;
}

.MainHeader_user__3nOSu:hover {
	cursor: pointer;
}

.MainHeader_user-menu__26w7B {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: absolute;
	top: 100%;
	background-color: #fff;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	min-width: 100%;
	z-index: 9999;
}

.MainHeader_user-menu__26w7B a,
.MainHeader_user-menu__26w7B span {
	margin: 0;
	cursor: pointer;
	padding: 10px 15px;
}

.MainHeader_user-menu__26w7B a:hover,
.MainHeader_user-menu__26w7B span:hover {
	background-color: #e8eaed;
}

.MainHeader_toggle-menu__2qGnd {
	-webkit-flex-direction: column;
	        flex-direction: column;
	display: none;
    position: relative;
    z-index: 9;
    height: 25px;
    width: 30px;
    overflow: hidden;
}

.MainHeader_toggle-menu__2qGnd span {
    width: 100%;
	height: 5px;
	background-color: black;
    position: absolute;
    right: 0;
    transition: all ease 0.3s;
    border-radius: 20px;
}

.MainHeader_toggle-menu__2qGnd span:nth-child(1) {
	top: 0;
}

.MainHeader_toggle-menu__2qGnd span:nth-child(2) {
	top: 10px;
}

.MainHeader_toggle-menu__2qGnd span:nth-child(3) {
	top: 20px;
}

.MainHeader_toggle-menu-active__2z6lL span:nth-child(1) {
	top: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.MainHeader_toggle-menu-active__2z6lL span:nth-child(2) {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
}

.MainHeader_toggle-menu-active__2z6lL span:nth-child(3) {
	top: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
@media screen and (max-width: 800px) {
	.MainHeader_header__b3nih {
		width: 100%;
	}
	.MainHeader_toggle-menu__2qGnd {
		display: -webkit-flex;
		display: flex;
	}

	.MainHeader_nav__3rIJH {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #fff;
		-webkit-flex-direction: column;
		        flex-direction: column;
		grid-gap: 20px;
		gap: 20px;
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
		transition: all ease 0.3s;
        text-align: center;
        margin-top: 63px;
	}

	.MainHeader_active__2mVoz {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}

    .MainHeader_user-menu__26w7B {
        box-shadow: none;
        left: 0;
    }
}

.User_user__2t2qA {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.User_user-avatar__D8rYN {
	width: 30px;
    height: 30px;
    object-fit: cover;
	border-radius: 50%;
    margin-right: 10px;
}

.Notification_wrapper__2jW7g {
	position: fixed;
	bottom: 15px;
	left: 15px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	        align-items: stretch;
	color: #fff;
	font-weight: bold;
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%);
	max-width: 500px;
	z-index: 9999;
}

.Notification_countdown__3MmDa {
	height: 7px;
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	top: 0;
	left: 0;
}

.Notification_close__2bX-1 {
	margin-top: 7px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding: 5px 25px;
	position: relative;
	font-size: 1.2rem;
}

.Notification_close__2bX-1:hover {
	cursor: pointer;
	color: #fff;
}

.Notification_close__2bX-1:hover:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	display: block;
	opacity: 0.2;
}

.Notification_text__2u6pm {
	padding: 20px;
	margin: 0;
	margin-top: 7px;
}

.Notification_alert__LvoIV {
	background-color: orangered;
}

.Notification_success__2Yh_7 {
	background-color: seagreen;
}

@media screen and (max-width: 800px) {
	.Notification_wrapper__2jW7g {
		max-width: calc(100% - 30px) !important;
	}
}

.MapToggle_map-toggle__2-0vq {
	position: fixed;
	width: 60px;
    height: 60px;
	background-color: #ffdb56;
	padding: 13px;
	border-radius: 50%;
	box-shadow: 0px 0px 6px 3px rgb(60 64 67 / 36%);
	display: none;
	z-index: 999;
}

.MapToggle_map-toggle__2-0vq .MapToggle_icon__3YwrK {
	-webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
	        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
}

@media screen and (max-width: 800px) {
	.MapToggle_map-toggle__2-0vq {
		display: block;
	}

	.MapToggle_map-container-active__2dPx- {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.LoadingSpinner_loader-wrapper__1pb6j {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.768);
    z-index: 9999;
}

.LoadingSpinner_loader__WmAXJ,
.LoadingSpinner_loader__WmAXJ:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}
.LoadingSpinner_loader__WmAXJ {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(255, 144, 144, 0.226);
	border-right: 1.1em solid rgba(255, 144, 144, 0.226);
	border-bottom: 1.1em solid rgba(255, 144, 144, 0.226);
	border-left: 1.1em solid #f80000;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: LoadingSpinner_load8__1pqQ6 1.1s infinite linear;
	animation: LoadingSpinner_load8__1pqQ6 1.1s infinite linear;
}
@-webkit-keyframes LoadingSpinner_load8__1pqQ6 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes LoadingSpinner_load8__1pqQ6 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

