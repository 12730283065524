.layout {
	display: flex;
}

.main-container {
	width: 55%;
	position: relative;
	max-width: 800px;
}

.main {
	padding: 20px 20px;
	margin-top: 63px;
	background: #f4f4f4;
	height: calc(100vh - 63px);
	overflow-y: scroll;
}

.map-container {
	width: 45%;
	overflow: hidden;
	position: relative;
	flex: 1;
}

.map-container::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 5px;
	left: -5px;
	top: 0;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	display: block;
	z-index: 999;
}



@media screen and (max-width: 800px) {
	.main-container {
		width: 100%;
	}

	.map-container {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		height: 100%;
		transform: translateX(100%);
		transition: 0.3s all ease;
		margin-top: 63px;
		height: calc(100% - 63px);
	}

	.main {
		padding: 20px 5px;
	}

	.map-toggle {
		display: block;
	}

	.map-container-active {
		transform: translateX(0);
	}
}
