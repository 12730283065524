.user {
    display: flex;
    align-items: center;
    width: fit-content;
}
.user-avatar {
	width: 30px;
    height: 30px;
    object-fit: cover;
	border-radius: 50%;
    margin-right: 10px;
}
